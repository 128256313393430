import React from 'react';

import styles from './FeatureCaption.module.scss';

const FeatureCaption: React.FC = ({ children }) => (
  <div className={styles.featureCaption}>
    {children}
  </div>
);

export default FeatureCaption;
