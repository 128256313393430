import React from 'react';

import styles from './FeatureVideo.module.scss';

interface FeatureVideoProps {
  video: string;
}

const FeatureVideo: React.FC<FeatureVideoProps> = ({ video }) => (
  <div className={styles.container}>
    <video
      loop
      muted
      autoPlay
      playsInline
    >
      <source src={video} type="video/mp4" />
    </video>
  </div>
);

export default FeatureVideo;
