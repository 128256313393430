import React from 'react';

import styles from './FeatureNumber.module.scss';

const FeatureNumber: React.FC = ({ children }) => (
  <div className={styles.number}>
    {children}
  </div>
);

export default FeatureNumber;
