import React from 'react';

import styles from './FeatureImage.module.scss';

const FeatureImage: React.FC = ({ children }) => (
  <div className={styles.container}>
    {children}
  </div>
);

export default FeatureImage;
