import React from 'react';
import { Helmet } from 'react-helmet';

import Features from 'components/Features';
import Layout from 'components/layout';

const FeaturesPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Features</title>
      <meta name="og:title" content="Features" />
      <meta name="twitter:title" content="Features" />
    </Helmet>
    <Features />
  </Layout>
);

export default FeaturesPage;
