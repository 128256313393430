import React from 'react';

import FeatureCaption from './FeatureCaption/index';
import FeatureDescription from './FeatureDescription';
import FeatureImage from './FeatureImage';
import FeatureNumber from './FeatureNumber';
import FeatureTitle from './FeatureTitle';
import FeatureVideo from './FeatureVideo';

import styles from './Feature.module.scss';

interface FeatureComponent extends React.FC {
  Description: typeof FeatureDescription;
  Image: typeof FeatureImage;
  Number: typeof FeatureNumber;
  Title: typeof FeatureTitle;
  Video: typeof FeatureVideo;
  Caption: typeof FeatureCaption;
}

const Feature: FeatureComponent = ({ children }) => (
  <div className={styles.feature}>
    {children}
  </div>
);

Feature.Description = FeatureDescription;
Feature.Image = FeatureImage;
Feature.Number = FeatureNumber;
Feature.Title = FeatureTitle;
Feature.Video = FeatureVideo;
Feature.Caption = FeatureCaption;

export {
  FeatureNumber,
  FeatureTitle,
  FeatureDescription,
  FeatureImage,
  FeatureVideo,
};

export default Feature;
