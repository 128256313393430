import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import feature1Video from '../../images/features/feature-1.mp4';

import Feature from './Feature';
import FeaturesHeader from './FeaturesHeader';

import styles from './Features.module.scss';

const Features: React.FC = () => {
  const {
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
  } = useStaticQuery(
    graphql`
      query {
        image2: file(relativePath: { eq: "features/feature-2.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image3: file(relativePath: { eq: "features/feature-3.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image4: file(relativePath: { eq: "features/feature-4.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image5: file(relativePath: { eq: "features/feature-5.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image6: file(relativePath: { eq: "features/feature-6.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image7: file(relativePath: { eq: "features/feature-7.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image8: file(relativePath: { eq: "features/feature-8.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image9: file(relativePath: { eq: "features/feature-9.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image10: file(relativePath: { eq: "features/feature-10.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image11: file(relativePath: { eq: "features/feature-11.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image12: file(relativePath: { eq: "features/feature-12.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image13: file(relativePath: { eq: "features/feature-13.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image14: file(relativePath: { eq: "features/feature-14.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image15: file(relativePath: { eq: "features/feature-15.png" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
      }
    `,
  );

  return (
    <div>
      <FeaturesHeader />
      <div className={styles.container}>
        <Feature>
          <Feature.Number>1</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_1.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_1.description" />
          </Feature.Description>
          <Feature.Video video={feature1Video} />
          <Feature.Caption>
            <b>
              <FormattedMessage id="components.features.feature_11.caption" />
              {' '}
            </b>
            <FormattedMessage id="components.features.coming_soon" />
          </Feature.Caption>
        </Feature>

        <Feature>
          <Feature.Number>2</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_2.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_2.description" />
          </Feature.Description>
          <Feature.Image>
            <GatsbyImage fluid={image2.childImageSharp.fluid} />
          </Feature.Image>
        </Feature>

        <Feature>
          <Feature.Number>3</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_3.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_3.description" />
          </Feature.Description>
          <Feature.Image>
            <GatsbyImage fluid={image3.childImageSharp.fluid} />
          </Feature.Image>
        </Feature>

        <Feature>
          <Feature.Number>4</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_4.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_4.description" />
          </Feature.Description>
          <Feature.Image>
            <GatsbyImage fluid={image4.childImageSharp.fluid} />
          </Feature.Image>
        </Feature>

        <Feature>
          <Feature.Number>5</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_5.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_5.description" />
          </Feature.Description>
          <Feature.Image>
            <GatsbyImage fluid={image5.childImageSharp.fluid} />
          </Feature.Image>
        </Feature>

        <Feature>
          <Feature.Number>6</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_6.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_6.description" />
          </Feature.Description>
          <Feature.Image>
            <GatsbyImage fluid={image6.childImageSharp.fluid} />
          </Feature.Image>
        </Feature>

        <Feature>
          <Feature.Number>7</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_7.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_7.description" />
          </Feature.Description>
          <Feature.Image>
            <GatsbyImage fluid={image7.childImageSharp.fluid} />
          </Feature.Image>
        </Feature>

        <Feature>
          <Feature.Number>8</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_8.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_8.description" />
          </Feature.Description>
          <Feature.Image>
            <GatsbyImage fluid={image8.childImageSharp.fluid} />
          </Feature.Image>
        </Feature>

        <Feature>
          <Feature.Number>9</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_9.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_9.description" />
          </Feature.Description>
          <Feature.Image>
            <GatsbyImage fluid={image9.childImageSharp.fluid} />
          </Feature.Image>
        </Feature>

        <Feature>
          <Feature.Number>10</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_10.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_10.description" />
          </Feature.Description>
          <Feature.Image>
            <GatsbyImage fluid={image10.childImageSharp.fluid} />
          </Feature.Image>
        </Feature>
        <Feature>
          <Feature.Number>11</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_11.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_11.description" />
          </Feature.Description>
          <Feature.Image>
            <GatsbyImage fluid={image11.childImageSharp.fluid} />
            <Feature.Caption>
              <b>
                <FormattedMessage id="components.features.feature_11.caption" />
                {' '}
              </b>
              <FormattedMessage id="components.features.coming_soon" />
            </Feature.Caption>
          </Feature.Image>
        </Feature>
        <Feature>
          <Feature.Number>12</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_12.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_12.description" />
          </Feature.Description>
          <Feature.Image>
            <GatsbyImage fluid={image12.childImageSharp.fluid} />
          </Feature.Image>
        </Feature>
        <Feature>
          <Feature.Number>13</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_13.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_13.description" />
          </Feature.Description>
          <Feature.Image>
            <GatsbyImage fluid={image13.childImageSharp.fluid} />
          </Feature.Image>
        </Feature>
        <Feature>
          <Feature.Number>14</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_14.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_14.description" />
          </Feature.Description>
          <Feature.Image>
            <GatsbyImage fluid={image14.childImageSharp.fluid} />
          </Feature.Image>
        </Feature>
        <Feature>
          <Feature.Number>15</Feature.Number>
          <Feature.Title>
            <FormattedMessage id="components.features.feature_15.title" />
          </Feature.Title>
          <Feature.Description>
            <FormattedMessage id="components.features.feature_15.description" />
          </Feature.Description>
          <Feature.Image>
            <GatsbyImage fluid={image15.childImageSharp.fluid} />
            <Feature.Caption>
              <FormattedMessage id="components.features.coming_soon" />
            </Feature.Caption>
          </Feature.Image>
        </Feature>
      </div>
    </div>
  );
};

export default Features;
