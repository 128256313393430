import React from 'react';

import ParallaxContainer from './ParallaxContainer';

import styles from './FeaturesHeader.module.scss';

const FeaturesHeader: React.FC = () => (
  <section className={styles.container}>
    <div className={styles.header}>
      <ParallaxContainer />
    </div>
  </section>
);

export default FeaturesHeader;
