import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import Parallax from 'react-rellax';

import styles from './ParallaxContainer.module.scss';

const ParallaxContainer: React.FC = () => {
  const {
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
  } = useStaticQuery(
    graphql`
        query {
            image1: file(relativePath: { eq: "features/parallax/image-1.png" }) {
                childImageSharp {
                    fixed(width: 400) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64,
                    }
                }
            }
            image2: file(relativePath: { eq: "features/parallax/image-2.png" }) {
                childImageSharp {
                    fixed(width: 400) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64,
                    }
                }
            }
            image3: file(relativePath: { eq: "features/parallax/image-3.png" }) {
                childImageSharp {
                    fixed(width: 400) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64,
                    }
                }
            }
            image4: file(relativePath: { eq: "features/parallax/image-4.png" }) {
                childImageSharp {
                    fixed(width: 750) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64,
                    }
                }
            }
            image5: file(relativePath: { eq: "features/parallax/image-5.png" }) {
                childImageSharp {
                    fixed(width: 400) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64,
                    }
                }
            }
            image6: file(relativePath: { eq: "features/parallax/image-6.png" }) {
                childImageSharp {
                    fixed(width: 400) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64,
                    }
                }
            }
            image7: file(relativePath: { eq: "features/parallax/image-7.png" }) {
                childImageSharp {
                    fixed(width: 400) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64,
                    }
                }
            }
            image8: file(relativePath: { eq: "features/parallax/image-8.png" }) {
                childImageSharp {
                    fixed(width: 400) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64,
                    }
                }
            }
            image9: file(relativePath: { eq: "features/parallax/image-9.png" }) {
                childImageSharp {
                    fixed(width: 400) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64,
                    }
                }
            }
        }
    `,
  );

  return (
    <div className={styles.container}>
      <div className={styles.parallax_1}>
        <Parallax as="div" speed={3}>
          <GatsbyImage fixed={image1.childImageSharp.fixed} className={styles.image} />
        </Parallax>
      </div>
      <div className={styles.parallax_2}>
        <Parallax as="div" speed={6}>
          <GatsbyImage fixed={image2.childImageSharp.fixed} className={styles.image} />
        </Parallax>
      </div>
      <div className={styles.parallax_3}>
        <Parallax as="div" speed={2}>
          <GatsbyImage fixed={image3.childImageSharp.fixed} className={styles.image} />
        </Parallax>
      </div>
      <div className={styles.parallax_4}>
        <Parallax as="div" speed={5}>
          <GatsbyImage fixed={image4.childImageSharp.fixed} className={styles.image} />
        </Parallax>
      </div>
      <div className={styles.parallax_5}>
        <Parallax as="div" speed={3}>
          <GatsbyImage fixed={image5.childImageSharp.fixed} className={styles.image} />
        </Parallax>
      </div>
      <div className={styles.parallax_6}>
        <Parallax as="div" speed={6}>
          <GatsbyImage fixed={image6.childImageSharp.fixed} className={styles.image} />
        </Parallax>
      </div>
      <div className={styles.parallax_7}>
        <Parallax as="div" speed={2}>
          <GatsbyImage fixed={image7.childImageSharp.fixed} className={styles.image} />
        </Parallax>
      </div>
      <div className={styles.parallax_8}>
        <Parallax as="div" speed={5}>
          <GatsbyImage fixed={image8.childImageSharp.fixed} className={styles.image} />
        </Parallax>
      </div>
      <div className={styles.parallax_9}>
        <Parallax as="div" speed={5}>
          <GatsbyImage fixed={image9.childImageSharp.fixed} className={styles.image} />
        </Parallax>
      </div>
    </div>
  );
};

export default ParallaxContainer;
